import React from 'react'
import Helmet from 'react-helmet'
import styles from './base.module.css'

import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import Chrome from '../../components/Chrome'

import screenshot from '../../../assets/feedback-portal-large.png'
import data from '../../../data/comparison.yaml'

export default class Page extends React.Component {
  renderItem = (item) => {
    return (
      <div className={styles.reason}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.us}>{item.us}</div>
        <div className={styles.them}>{item.them}</div>
      </div>
    )
  }

  render() {
    const dataItems = data.trello

    return (
      <Layout>
        <div className={styles.root}>
          <Helmet title={'Trello alternative - Suggested'}></Helmet>

          <div className={styles.intro}>
            <h1>Trello alternative</h1>
            <h3>Try Suggested, designed for product people.</h3>
          </div>

          <div className={styles.lead}>
            <p>
              While Trello is general project management software, and is
              sometimes used to organise customer feedback - it is not
              specifically designed for: customer feedback management, public
              roadmap, or product changelog
            </p>
            <p>
              Suggested on the other hand is designed for collecting and managing
              customer feedback, public roadmaps, and changelogs. We don't think
              of Suggested as an alternative for Trello, but we think it's better
              suited if you're planning to use Trello was product management &
              feedback capture.
            </p>
          </div>

          <div className={styles.screenshot}>
            <Chrome>
              <img src={screenshot} alt="screenshot" />
            </Chrome>
          </div>

          <div className={styles.comparison}>
            <h1>Why our customers love Suggested</h1>
            <div className={styles.items}>
              {dataItems.map((item) => this.renderItem(item))}
            </div>
          </div>

          <div className="signup-container">
            <SignupButton />
          </div>
        </div>
      </Layout>
    )
  }
}
